<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex flex-column fill-height position-relative px-4">
    <div class="text-h5 px-3 mb-3">Reports</div>
    <v-tabs v-model="tab">
      <v-tab href="#tab-1">Sales</v-tab>

      <v-tab href="#tab-2">Lead-Patient</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1" class="pa-2">
        <sales />
        <v-divider class="my-4"></v-divider>
        <financial />
      </v-tab-item>
      <v-tab-item value="tab-2" class="pa-2">
        <chart-leads />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Sales from "@/views/Configurations/Sales.vue";
import Financial from "@/views/Configurations/Financial.vue";
import ChartLeads from "@/views/Dashboard/Finantial/ChartLeads.vue";
export default {
  components: { Sales, Financial, ChartLeads },
  name: "reports",
  data() {
    return {
      tab: null,
    };
  },
};
</script>
<style lang="scss" scoped></style>
