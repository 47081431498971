











import Vue from "vue";
import CartRecentSales from "@/views/Dashboard/Finantial/ChartRecentSales.vue";

// import NextApponments from "./CharNextApponments.vue";

export default Vue.extend({
  components: { CartRecentSales },
  name: "dashboard-finantial",
});
